"use client"
import React, { useState, useEffect } from 'react';
import Image from 'next/image'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getRecognitionData  } from '../lib/comman';
interface PartenersLogos {
    parteners: {
      partenerLogo: {
        altText: string;
        sourceUrl: string;
      };
    }[];
  }
  
interface RecognitionData {
    partenersLogos: PartenersLogos;
}

function PartnersThrre() {

    const [recognitionData, setRecognitionData] = useState<RecognitionData | null>(null);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const data = await getRecognitionData();
            setRecognitionData(data);
          } catch (error) {
            console.error('Error fetching recognition data:', error);
          }
        };
      
        fetchData();
    }, []);

    const partenersLogos = recognitionData?.partenersLogos;

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        // fade: true,
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              // arrows:false,
           
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
          
              slidesToScroll: 1
            }
          }
        ]
      };
  return (
    <Slider {...settings}>
        {
            partenersLogos && partenersLogos?.parteners.map((logo:any,index:any)=>{
            return(
                <div key={index} className="item">
                    <Image src={logo?.partenerLogo?.sourceUrl} alt={logo?.partenerLogo?.altText}  width={186} height={83} loading="lazy"/>
                </div>
            )
            })
        }
    </Slider>
  )
}

export default PartnersThrre