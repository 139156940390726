import React, { useState, useEffect } from 'react';
import { GetSocialMedia } from '../../lib/comman';
import Link from 'next/link';
import Image from 'next/image'
function WhatsApp() {
 
  const [whatsUpUrl, setwhatsUpUrl] = useState<any>(null);

  useEffect(() => {
    const fetchWhatsUpData = async () => {
      try {
        const data = await GetSocialMedia();
        setwhatsUpUrl(data);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchWhatsUpData();
  }, []);

  return (
    <> 
        {whatsUpUrl?.whatsAppUrl && (
            <Link className="whats_app" href={whatsUpUrl?.whatsAppUrl} target='_blank' data-aos="fade-left" data-aos-delay="100">
                <Image src="/images/whats-app.png" alt="whatsapp" width={185} height={86} loading="lazy"/>
            </Link>
        )}
    </>
  )
}

export default WhatsApp
